
import { MONTH_EN, MONTH_EN_SHORT} from "@/src/constants/constants";
import { DAYOFWEEK_CHOICES } from "../constants/dropdownChoices";
import { calcEndTime } from "./date-time/time";


// export:
// Friday, March 12
// 3月12日（金）
export const displayDate1 = (datetime: Date, locale:'en' | 'jp' = 'jp') : string => {
    // Sunday or 日曜日
    const dow = DAYOFWEEK_CHOICES[datetime.getDay()][locale]
    // 3月 or March
    const monthString = locale=='en' ? MONTH_EN[datetime.getMonth()] : `${datetime.getMonth()+1}月`
    //date
    const date = datetime.getDate()

    // Sunday, April 20
    if (locale=='en') {
        return `${dow}, ${monthString} ${date}`
    }
    // 3月12日（金）
    else {
        return `${monthString}${date}日（${dow}）`
    }
}

// no locale:
// MM/DD/YYYY HH:MM
export const displayDate2 = (date:Date):string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    
    // // Remove leading zeros from the month, day, and hour
    // const cleanedDate: string = formattedDate.replace(/(^|\/| )0+/g, '$1');
    return formattedDate.replace(',', '');
}


// no locale:
// MM/DD/YYYY
export const displayDate3 = (date:Date):string => {
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
}


// MM/DD/YYYY if different year else MM/DD
export const displayDate9 = (date: Date): string => {
  const now = new Date();
  const isSameYear = date.getFullYear() === now.getFullYear();

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    ...(isSameYear ? {} : { year: 'numeric' })  // Include year if it's a different year
  };

  return date.toLocaleDateString('en-US', options);
}

// MM/DD/YY HH:MM if different year 
// MM/DD HH:MM if same year
export const displayDate10 = (date: Date): string => {
  const now = new Date();
  const isSameYear = date.getFullYear() === now.getFullYear();

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    ...(isSameYear ? {} : { year: '2-digit' })  // Include year if it's a different year
  };

  return date.toLocaleDateString('en-US', options).replace(',', '');
}



// no locale:
// Friday, March 12, 14:00 - 15:00
// 3月12日（金）14:00 - 15:00
export const displayDate4 = (dateObj:Date, duration:number, locale:'en' | 'jp' = 'jp'):string => {
    const startDate = displayDate1(dateObj, locale)

    // Extract hour and minutes
    const startHour = dateObj.getHours();
    const startMin = dateObj.getMinutes();

    // Pad single-digit hours/minutes with leading zero
    const formattedHours = String(startHour).padStart(2, '0');
    const formattedMinutes = String(startMin).padStart(2, '0');
    const startTime = `${formattedHours}:${formattedMinutes}`;

    const endTime = calcEndTime(startTime, duration)

    return `${startDate} ${startTime}-${endTime}`
}


// no locale:
// YYYY/MM/DD
export const displayDate5 = (date: Date): string => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    
    return `${year}/${month}/${day}`;
}


// no locale:
// MM/DD/YYYY HH:MM
export const displayDate6 = (date:Date):string => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
}


// locale
// Mar 12
// 3月12日
export const displayDateShort1 = (dateObj: Date, locale:'en'|'jp'='jp'):string => {
    const date = dateObj.getDate()
  
    if (locale=='en') {
        const monthString = MONTH_EN_SHORT[dateObj.getMonth()]
        return `${monthString} ${date}`
    } else {
        return `${dateObj.getMonth()+1}月${date}日`
    }
}

// Mar 12 , 00:00
// 3月12日 , 00:00
export const displayDateShort2 = (dateObj: Date, locale: 'en' | 'jp' = 'jp'): string => {
    const date = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // 分が1桁のときに2桁にする
    const time = `${hours}:${minutes}`;

    if (locale === 'en') {
        const monthString = MONTH_EN_SHORT[dateObj.getMonth()];
        return `${monthString} ${date}, ${time}`;
    } else {
        return `${dateObj.getMonth() + 1}月${date}日 ${time}`;
    }
}
// locale
// Mar 12 14:00
// 3月12日 14:00
export const displayDatetimeShort1 = (dateObj: Date, locale:'en'|'jp'='jp'):string => {
    const date = dateObj.getDate()

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  
    if (locale=='en') {
        const monthString = MONTH_EN_SHORT[dateObj.getMonth()]
        return `${monthString} ${date}, ${hours}:${minutes}`
    } else {
        return `${dateObj.getMonth()+1}月${date}日 ${hours}:${minutes}`
    }
}



// Friday, March 12, 14:00 - 15:00 +2
// 3月12日（金）14:00 - 15:00 +2
export const displayDate7 = (start_datetime:Date, end_datetime:Date, locale:'en' | 'jp' = 'jp'):string => {
    // Extract hour and minutes
    const startHour = ('0' + start_datetime.getHours()).slice(-2);
    const startMin = ('0' + start_datetime.getMinutes()).slice(-2);
    // Extract hour and minutes
    const endHour = ('0' + end_datetime.getHours()).slice(-2);
    const endMin = ('0' + end_datetime.getMinutes()).slice(-2);
    // Calculate the day difference between start and end dates
    const dayDifference = Math.floor((end_datetime.getTime() - start_datetime.getTime()) / (1000 * 60 * 60 * 24));
    // Build the base date and time string
    let result = `${displayDate1(start_datetime, locale)} ${startHour}:${startMin} - ${endHour}:${endMin}`;

    // Append the day difference if it's greater than zero
    if (dayDifference > 0) {
      result += ` +${dayDifference}`;
    }
  return result;
}

// 2024.3.12 14:00 ~ 2024.3.13 15:00
// 2024.3.12 14:00 ~ 15:00
export const displayDate8 = (start_datetime: Date, end_datetime: Date): string => {
    const startDate = `${start_datetime.getFullYear()}.${start_datetime.getMonth() + 1}.${start_datetime.getDate()}`;
    const startTime = `${start_datetime.getHours()}:${String(start_datetime.getMinutes()).padStart(2, '0')}`;
    const endDate = `${end_datetime.getFullYear()}.${end_datetime.getMonth() + 1}.${end_datetime.getDate()}`;
    const endTime = `${end_datetime.getHours()}:${String(end_datetime.getMinutes()).padStart(2, '0')}`;
  
    if (startDate === endDate) {
      return `${startDate} ${startTime} ~ ${endTime}`;
    } else {
      return `${startDate} ${startTime} ~ ${endDate} ${endTime}`;
    }
  }


// Thurs Oct 10, 12:00 - 14:00 || Thurs Oct 10, 12:00 - Fri Oct 9, 14:00
// 10月29日(火) 12:00 - 16:00 || 10月29日(火) 12:00 - 10月29日(火) 16:00
export const displayDatetimeDuration1 = (start_datetime:Date, end_datetime:Date, locale:'en'|'jp'='jp'):string => {
  // English formatters
  const enDateFormatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',  // 24-hour format
  });
  const enTimeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',  // 24-hour format
  });
  // Japanese formatters
  const jpDateFormatter = new Intl.DateTimeFormat('ja-JP', {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
  const jpTimeFormatter = new Intl.DateTimeFormat('ja-JP', {
    hour: 'numeric',
    minute: 'numeric',
  });
  // Check if start and end date are on the same day
  const isSameDay = start_datetime.toDateString() === end_datetime.toDateString();
  if (locale === 'en') {
    // English formatting
    if (isSameDay) {
      // Same day format: "Thurs Oct 10, 12:00 - 14:00"
      return `${enDateFormatter.format(start_datetime)} - ${enTimeFormatter.format(end_datetime)}`;
    } else {
      // Different day format: "Thurs Oct 10, 12:00 - Fri Oct 9, 14:00"
      return `${enDateFormatter.format(start_datetime)} - ${enDateFormatter.format(end_datetime)}`;
    }
  } else if (locale === 'jp') {
    // Japanese formatting
    if (isSameDay) {
      // Same day format: "10月29日(火) 12:00 - 16:00"
      return `${jpDateFormatter.format(start_datetime)} - ${jpTimeFormatter.format(end_datetime)}`;
    } else {
      // Different day format: "10月29日(火) 12:00 - 10月30日(水) 16:00"
      return `${jpDateFormatter.format(start_datetime)} - ${jpDateFormatter.format(end_datetime)}`;
    }
  }
  // Fallback if locale is not recognized
  return '';
}


// 1 day, 5 hours, 4 minutes
// 1日4時間5分
export const calcDuration1 = (start_datetime:Date, end_datetime:Date, locale:'en'|'jp'='jp'):string => {
  // Calculate the difference in milliseconds
  const diffInMs = end_datetime.getTime() - start_datetime.getTime();
  // Convert milliseconds into days, hours, and minutes
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  if (locale === 'en') {
    // English formatting: "1 day, 4 hours, 4 minutes"
    const dayStr = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';
    const hourStr = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
    const minuteStr = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
    return [dayStr, hourStr, minuteStr].filter(Boolean).join(', ');
  } else if (locale === 'jp') {
    // Japanese formatting: "1日 4時間 2分"
    const dayStr = days > 0 ? `${days}日` : '';
    const hourStr = hours > 0 ? `${hours}時間` : '';
    const minuteStr = minutes > 0 ? `${minutes}分` : '';
    return [dayStr, hourStr, minuteStr].filter(Boolean).join(' ');
  }

  // Fallback if locale is not recognized
  return '';
}


// function: displayDayOfWeek
export const displayDayOfWeek = (dateObj: Date, locale:'en'|'jp'='jp') : string => {
    const dow = DAYOFWEEK_CHOICES[dateObj.getDay()][locale]
    return dow
}

// function: displayMonth
// jp: 3月
// en: March
export const displayMonth = (dateObj: Date, locale:'en'|'jp'='jp') : string => {
    const month = dateObj.getMonth()+1
    if (locale=='en') {
      return `${MONTH_EN[month-1]}`
    } else {
      return `${month}月`
    }
}

// output: 24
export const displayDay = (dateObj: Date) : string => {
    const day = dateObj.getDate();
    return `${day}`
}




// no locale:
// HH:MM
export const displayTime1 = (date:Date):string => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}


// 14:00 - 16:00 +2
export const displayTimeRange = (start_datetime: Date, end_datetime: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
  };

  const time1 = new Intl.DateTimeFormat('default', options).format(start_datetime);
  const time2 = new Intl.DateTimeFormat('default', options).format(end_datetime);

  // Calculate the day difference between start and end dates
  const dayDifference = Math.floor((end_datetime.getTime() - start_datetime.getTime()) / (1000 * 60 * 60 * 24));

  // Append the day difference if it's greater than zero
  return dayDifference > 0 ? `${time1} - ${time2} +${dayDifference}` : `${time1} - ${time2}`;
}




/**
 * 
 * @param date Datetime object
 * output: 1 week ago, 1 minute ago, etc...
 */
export const calcTimePassed = (date: Date, locale:'en'|'jp'='jp') => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
  
    if (weeks > 0) {
        if (locale=='en') {
            return`${weeks} week${weeks === 1 ? '' : 's'} ago`
        } else {
            return`${weeks}週間前`
        }
    } else if (days > 0) {
        if (locale=='en') {
            return`${days} day${days === 1 ? '' : 's'} ago`
        } else {
            return`${days}日前`
        }
    } else if (hours > 0) {
        if (locale=='en') {
            return`${hours} hour${hours === 1 ? '' : 's'} ago`
        } else {
            return`${hours}時間前`
        }
    } else if (minutes > 0) {
        if (locale=='en') {
            return`${minutes} minute${minutes === 1 ? '' : 's'} ago`
        } else {
            return`${minutes}分前`
        }
    } else {
        if (locale=='en') {
            return`${seconds} second${seconds === 1 ? '' : 's'} ago`
        } else {
            return`${seconds}秒前`
        }
    }
  }

export const tomorrow = ():Date => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow

}


export const twoWeeksFromNowDate = () => {
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
    return twoWeeksFromNow
}



export const setTimeInDate = (date: Date, timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0); // Optionally reset seconds to 0 if needed
    return date;
  }


// input: Date , output: 'HH:SS'
export const timeFromDatetime = (date:Date) => {
    if (date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        return `${hours}:${minutes}`;
    } else {
        return ''
    }
}


export const isEndTimeAfterStartTime = (starttime: string, endtime: string): boolean =>  {
    // Parse the time strings into hours and minutes
    const [startHours, startMinutes] = starttime.split(':').map(Number);
    const [endHours, endMinutes] = endtime.split(':').map(Number);
  
    // Create two Date objects for comparison, using the same date for both
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startHours, startMinutes);
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endHours, endMinutes);
  
    // Check if the end time is after the start time
    return endDate > startDate;
  }


export const isSameDate = (date1: Date, date2: Date): boolean  => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() && // getMonth() is zero-indexed (0 = January, 11 = December)
      date1.getDate() === date2.getDate()
    );
}
  
 
export const isSameTime = (date1:Date, date2:Date): boolean => {
    // Ensure both inputs are Date objects
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    return (
      d1.getHours() === d2.getHours() &&
      d1.getMinutes() === d2.getMinutes()
    );
  };